import {IImageCollectionResponse, ImageCollectionModel} from '../responses/image-collection.model';
import {Type} from 'class-transformer';

export interface IGalleryInfoFavorites {
  imageCollectionId: string;
  photos: IImageCollectionResponse[];
}

export class GalleryInfoFavorites implements IGalleryInfoFavorites {
  imageCollectionId!: string;

  @Type(() => ImageCollectionModel)
  photos!: ImageCollectionModel[];
}
