import {VisitorStatus} from '../../enums/visitor-status.enum';

export interface IVisitorResponse {
  id: string;
  email: string;
  roles: string[];
  status: VisitorStatus;
}

export class VisitorModel implements IVisitorResponse {
  email!: string;
  id!: string;
  roles!: string[];
  status!: VisitorStatus;
}
