import {Type} from 'class-transformer';
import {IVisitorSubmissionResponse, VisitorSubmissionModel} from './visitor-submition.model';
import {IVisitorResponse, VisitorModel} from './visitor.model';
import {FavoriteImageModel, IFavoriteImageResponse} from './favorite-image.model';

export interface IVisitorSubmissionsResponse {
  submissions: IVisitorSubmissionResponse[];
  visitor: IVisitorResponse;

  favorites: IFavoriteImageResponse[];
}

export class VisitorSubmissionsModel {
  @Type(() => VisitorSubmissionModel)
  submissions!: VisitorSubmissionModel[];

  @Type(() => VisitorModel)
  visitor!: VisitorModel;

  @Type(() => FavoriteImageModel)
  favorites!: FavoriteImageModel[];
}
