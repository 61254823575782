import {IImageCollection, ImageCollectionModel} from '../models/image-collection';
import {Type} from 'class-transformer';

export interface IGalleryHiddenPhotosInfo {
  imageCollectionId: string;
  photos: IImageCollection[];
}

export class GalleryInfoHiddenModel implements IGalleryHiddenPhotosInfo {
  imageCollectionId!: string;

  @Type(() => ImageCollectionModel)
  photos!: ImageCollectionModel[];
}
