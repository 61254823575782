import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, Observable, of, shareReplay, tap} from 'rxjs';
import {GalleryInfoHiddenModel} from '../entities/interfaces/gallery-info.interface';
import {HiddenImageModel} from '../entities/models/hidden-image-response';
import {HiddenPhotosDataService} from '../infrastructure/hidden-photos-data.service';
import {GalleryImageId} from '@px/psf-themes-core';

@Injectable()
export class HiddenPhotosFacadeService {
  private readonly hiddenPhotosInternal$ = new BehaviorSubject<Record<GalleryImageId, boolean>>({});

  private collection: HiddenImageModel[] = [];
  private galleryInfo: GalleryInfoHiddenModel;

  hiddenPhotos$: Observable<Record<GalleryImageId, boolean>> = this.hiddenPhotosInternal$.pipe(
    map((hiddenPhotos: Record<GalleryImageId, boolean>) => this.mapHiddenPhotosToGalleryPhotos(hiddenPhotos)),
    shareReplay(1)
  );

  get hiddenPhotos(): Record<GalleryImageId, boolean> {
    return this.hiddenPhotosInternal$.value;
  }

  constructor(private readonly hiddenPhotosDataService: HiddenPhotosDataService) {}

  mapHiddenPhotosToGalleryPhotos(hiddenPhotos: Record<GalleryImageId, boolean>): Record<GalleryImageId, boolean> {
    const result: Record<GalleryImageId, boolean> = {};
    for (const photo of this.galleryInfo?.photos ?? []) {
      const id = photo.getId();

      if (hiddenPhotos[id]) {
        result[id] = hiddenPhotos[id];
      }
    }

    return result;
  }

  updateImageHiddenState(imageCollectionId: string, imageId: GalleryImageId, state: boolean): Observable<void> {
    const hiddenPhotos = Object.assign({}, this.hiddenPhotosInternal$.value, {
      [imageId]: state,
    });

    this.hiddenPhotosInternal$.next(hiddenPhotos);

    const id = this.galleryInfo.photos.find(item => item.getId() === imageId)?.picId ?? imageId;

    return this.hiddenPhotosDataService.updateHiddenPhotoFlag(imageCollectionId, id as number, state).pipe(
      tap(response => {
        this.collection = [...this.collection.filter(item => item.image.getId() !== response.image.getId()), response];
      }),
      map(() => undefined)
    );
  }

  updateHiddenPhotos(galleryHiddenPhotosInfo: GalleryInfoHiddenModel): Observable<void> {
    this.galleryInfo = galleryHiddenPhotosInfo;

    return this.hiddenPhotosDataService.collectionHiddenPhotos(galleryHiddenPhotosInfo.imageCollectionId).pipe(
      catchError(err => {
        console.error(err);
        return of([]);
      }),
      tap((collections: HiddenImageModel[]) => {
        this.collection = collections;

        const hiddenPhotos: Record<GalleryImageId, boolean> = {};

        for (const response of collections) {
          if (!response) continue;
          hiddenPhotos[response.image.getId()] = response.state;
        }

        this.hiddenPhotosInternal$.next(hiddenPhotos);
      }),
      map(() => undefined)
    );
  }

  hasHiddenHistory(): boolean {
    return !!this.collection?.length;
  }

  clearHiddenPhotos(): void {
    this.hiddenPhotosInternal$.next({});
  }
}
