import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, mergeMap, Observable, of, shareReplay, tap, throwError} from 'rxjs';
import {map} from 'rxjs/operators';
import {VisitorModel} from '../entities/responses/visitor.model';
import {NotSubmittedFavoritesVisitor} from '../entities/interfaces/not-submitted-favorites-visitor.error';
import {VisitorSubmissionsModel} from '../entities/responses/visitor-submissions.model';
import {head} from 'ramda';
import {ShowOnlyParams} from '../enums/show-only-params.enum';
import {IVisitorFavorites, UnsubmittedSnapshot} from '../entities/interfaces/visitor-favorites.interface';
import {FavoritesDataService} from '../infrastructure/favorites-data.service';
import {FavoriteImageModel} from '../entities/responses/favorite-image.model';
import {SaPingDataService} from '@px/shared/data-access/sa-data-ping';
import {GalleryImageId} from '../entities/interfaces/image-id.interface';

@Injectable()
export class PhotographerFavoritesFacadeService {
  private readonly saPingDataService = inject(SaPingDataService);
  private readonly favoritesDataService = inject(FavoritesDataService);

  private readonly favoritesInternal$ = new BehaviorSubject<Record<GalleryImageId, boolean> | null>(null);
  private readonly visitorInternal$ = new BehaviorSubject<VisitorModel | null>(null);

  activeFavorites$ = this.favoritesInternal$.asObservable();
  activeVisitor$ = this.visitorInternal$.asObservable();
  saRunningStatus$ = this.saPingDataService.ping().pipe(shareReplay(1));

  // PSF CA
  updateVisitorFavorites(imageCollectionId: string, visitorId: string, showOnly?: ShowOnlyParams): Observable<void> {
    return this.favoritesDataService.getVisitorsSubmissions(imageCollectionId).pipe(
      map(visitorsSubmissions => {
        return visitorsSubmissions.find(vs => vs.visitor?.id === visitorId);
      }),
      mergeMap((visitorSubmission: VisitorSubmissionsModel | undefined) =>
        visitorSubmission ? of(visitorSubmission) : throwError(() => new NotSubmittedFavoritesVisitor())
      ),
      tap((visitorSubmission: VisitorSubmissionsModel) =>
        this.visitorInternal$.next(visitorSubmission.visitor ?? null)
      ),
      map((visitorSubmissions: VisitorSubmissionsModel) => {
        if (showOnly === ShowOnlyParams.FAVORITES) {
          return visitorSubmissions.favorites.filter(item => item.state).map(item => item.image.getId());
        }

        const last = head(
          visitorSubmissions.submissions.sort((a, b) => (b.dateCreated.isAfter(a.dateCreated) ? 1 : -1))
        );

        return last?.favoritesSnapshot ?? [];
      }),
      tap((snapshot: GalleryImageId[]) => {
        const favorites: Record<GalleryImageId, boolean> = {};

        for (const i of snapshot ?? []) {
          favorites[i] = true;
        }

        this.favoritesInternal$.next(favorites);
      }),
      map(() => undefined)
    );
  }

  // P1 related
  getVisitorFavorites(
    imageCollectionId: string,
    config?: {watch: boolean; ttl: number}
  ): Observable<IVisitorFavorites[]> {
    const visitorsSubmissions = this.favoritesDataService.getVisitorsSubmissions(
      imageCollectionId,
      config?.watch ? config.ttl : undefined
    );

    return visitorsSubmissions.pipe(
      map((visitorSubmissions: VisitorSubmissionsModel[] | null) => {
        const favorites = visitorSubmissions?.map((vs): IVisitorFavorites => {
          const unsubmittedSnapshot =
            vs.favorites.reduce(
              (acc: UnsubmittedSnapshot, item: FavoriteImageModel) => {
                if (item.state) {
                  acc.favoritesSnapshot.push(item.image.getId());
                  acc.dateCreated = acc.dateCreated?.isBefore(item.dateCreated) ? acc.dateCreated : item.dateCreated;
                  acc.dateUpdated = acc.dateUpdated?.isAfter(item.dateUpdated) ? acc.dateUpdated : item.dateUpdated;
                }

                return acc;
              },
              {favoritesSnapshot: [], dateCreated: null, dateUpdated: null}
            ) ?? null;

          const submittedSnapshot =
            head(vs.submissions.sort((a, b) => (b.dateCreated.isAfter(a.dateCreated) ? 1 : -1))) ?? null;

          const addUnsubmittedList =
            !submittedSnapshot || unsubmittedSnapshot.dateUpdated?.isAfter(submittedSnapshot?.dateCreated);

          return {
            visitor: vs.visitor,
            submittedSnapshot,
            unsubmittedSnapshot: addUnsubmittedList ? unsubmittedSnapshot : null,
          };
        });

        return (
          (favorites?.filter((vs: IVisitorFavorites): boolean => {
            return (
              !!vs.submittedSnapshot?.favoritesSnapshot.length || !!vs.unsubmittedSnapshot?.favoritesSnapshot.length
            );
          }) as IVisitorFavorites[]) ?? []
        );
      })
    );
  }

  // P1 related
  resetFavoritesCache(): void {
    this.favoritesDataService.dropFavoritesCache();
  }
}
