import {CollectionArchiveResolution} from '../enums/collection-archive-resolution.enum';
import {GalleryImageId} from '@px/psf-themes-core';

export interface IDownloadBasePayload {
  collectionId: string;
  size: CollectionArchiveResolution;
  downloadPin?: string;
}

export interface IDownloadImagePayload extends IDownloadBasePayload {
  imageId: GalleryImageId;
}

export interface IDownloadFavoritesPayload extends IDownloadBasePayload {
  imageIds: GalleryImageId[];
}

export interface IDownloadGalleryPayload extends IDownloadBasePayload {
  email: string;
}

export type IDownloadPinValidationPayload = Required<IDownloadBasePayload>;
