import {VisitorModel} from '../responses/visitor.model';
import {VisitorSubmissionModel} from '../responses/visitor-submition.model';
import {Dayjs} from 'dayjs';
import {GalleryImageId} from './image-id.interface';

export type UnsubmittedSnapshot = {
  dateUpdated: Dayjs | null;
  dateCreated: Dayjs | null;
  favoritesSnapshot: GalleryImageId[];
};

export interface IVisitorFavorites {
  visitor: VisitorModel;
  submittedSnapshot: VisitorSubmissionModel | null;
  unsubmittedSnapshot: UnsubmittedSnapshot | null;
}
