import {inject, Injectable} from '@angular/core';
import {filter, map, Observable} from 'rxjs';
import {HiddenImageModel, IHiddenImageResponse} from '../entities/models/hidden-image-response';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {Apollo, gql} from 'apollo-angular';
import {plainToInstance} from 'class-transformer';
import {IUpdateHiddenPhotoFlagInput} from '../entities/inputs/update-hidden-photo-flag-input.interface';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class HiddenPhotosDataService {
  private readonly apollo = inject(Apollo);
  private readonly product: ProductFamily = inject(PRODUCT_FAMILY);
  private readonly platform = inject(PlatformEnvironment);

  private readonly collectionHiddenPhotosOldQuery = gql<
    {collectionHiddenPhotos: IHiddenImageResponse[]},
    {imageCollectionId: string; productFamily: ProductFamily}
  >`
    query CollectionHiddenPhotos($imageCollectionId: String!, $productFamily: ProductFamily!) {
      collectionHiddenPhotos(imageCollectionId: $imageCollectionId, productFamily: $productFamily) {
        image {
          picId
          originalFileName
        }
        productFamily
        dateCreated
        dateUpdated
        state
      }
    }
  `;

  private readonly collectionHiddenPhotosQuery = gql<
    {collectionHiddenPhotos: IHiddenImageResponse[]},
    {imageCollectionId: string; productFamily: ProductFamily}
  >`
    query CollectionHiddenPhotos($imageCollectionId: String!, $productFamily: ProductFamily!) {
      collectionHiddenPhotos(imageCollectionId: $imageCollectionId, productFamily: $productFamily) {
        image {
          id
          picId
          originalFileName
        }
        productFamily
        dateCreated
        dateUpdated
        state
      }
    }
  `;

  private readonly updateHiddenPhotoFlagMutation = gql<
    {updateHiddenPhotoFlag: IHiddenImageResponse},
    {input: IUpdateHiddenPhotoFlagInput}
  >`
    mutation UpdateHiddenPhotoFlag($input: UpdateHiddenPhotoFlagInput!) {
      updateHiddenPhotoFlag(input: $input) {
        image {
          picId
          originalFileName
        }
        productFamily
        dateCreated
        dateUpdated
        state
      }
    }
  `;

  collectionHiddenPhotos(imageCollectionId: string): Observable<HiddenImageModel[]> {
    return this.apollo
      .query({
        query: this.platform.hasFeature('PROXY_GQL')
          ? this.collectionHiddenPhotosQuery
          : this.collectionHiddenPhotosOldQuery,
        variables: {
          imageCollectionId,
          productFamily: this.product,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(HiddenImageModel, data.collectionHiddenPhotos))
      );
  }

  updateHiddenPhotoFlag(imageCollectionId: string, imageId: number, state: boolean): Observable<HiddenImageModel> {
    return this.apollo
      .mutate({
        mutation: this.updateHiddenPhotoFlagMutation,
        variables: {
          input: {
            imageCollectionId,
            imageId,
            state,
            productFamily: this.product,
          },
        },
      })
      .pipe(
        filter(result => !!result),
        map(result => plainToInstance(HiddenImageModel, result.data?.updateHiddenPhotoFlag))
      );
  }
}
