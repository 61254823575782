import {inject, Injectable} from '@angular/core';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';
import {filter, map, Observable} from 'rxjs';
import {CollectionArchiveResolution} from '../entities/enums/collection-archive-resolution.enum';
import {CollectionArchiveLinks, ICollectionArchiveLinks} from '../entities/models/collection-archive-links.interface';
import {CollectionDownloadType} from '../entities/enums/collection-download-type.enum';
import {DownloadRequestSource} from '../entities/enums/download-request-source.enum';
import {
  ICollectionArchiveContext,
  IImageDownloadContext,
  IRequestCollectionArchiveContext,
} from '../entities/models/collection-archive-context.interface';
import {Apollo, gql} from 'apollo-angular';
import {
  ICollectionArchivePayload,
  ICollectionPinValidationPayload,
  IImageDownloadPayload,
} from '../entities/models/collection-archive-payload.interface';
import {plainToInstance} from 'class-transformer';

@Injectable()
export class CollectionArchiveDataService {
  private readonly product: ProductFamily = inject(PRODUCT_FAMILY);
  private readonly apollo = inject(Apollo);

  private readonly requestCollectionArchiveMutation = gql<
    {requestCollectionArchive: ICollectionArchiveLinks},
    ICollectionArchivePayload<IRequestCollectionArchiveContext>
  >`
    mutation RequestCollectionArchive(
      $imageCollectionId: String!
      $resolution: CollectionArchiveResolution!
      $productFamily: ProductFamily!
      $context: RequestCollectionArchiveContext
    ) {
      requestCollectionArchive(
        imageCollectionId: $imageCollectionId
        productFamily: $productFamily
        requestArchiveContext: $context
        resolution: $resolution
      ) {
        domain
        links
      }
    }
  `;

  private readonly requestCollectionArchivePublicMutation = gql<
    {requestCollectionArchivePublic: ICollectionArchiveLinks},
    ICollectionArchivePayload<IRequestCollectionArchiveContext>
  >`
    mutation RequestCollectionArchivePublic(
      $imageCollectionId: String!
      $resolution: CollectionArchiveResolution!
      $productFamily: ProductFamily!
      $context: RequestCollectionArchivePublicContext!
    ) {
      requestCollectionArchivePublic(
        imageCollectionId: $imageCollectionId
        resolution: $resolution
        productFamily: $productFamily
        requestArchiveContext: $context
      ) {
        domain
        links
      }
    }
  `;

  private readonly collectionArchiveLinksQuery = gql<
    {collectionArchiveLinks: ICollectionArchiveLinks},
    ICollectionArchivePayload<ICollectionArchiveContext>
  >`
    query CollectionArchiveLinks(
      $imageCollectionId: String!
      $resolution: CollectionArchiveResolution!
      $productFamily: ProductFamily!
      $context: CollectionArchiveContext
    ) {
      collectionArchiveLinks(
        imageCollectionId: $imageCollectionId
        resolution: $resolution
        productFamily: $productFamily
        archiveContext: $context
      ) {
        domain
        links
      }
    }
  `;

  private readonly imageDownloadLinkQuery = gql<
    {imageDownloadLink: string},
    IImageDownloadPayload<IImageDownloadContext>
  >`
    query ImageDownloadLink(
      $imageCollectionId: String!
      $resolution: CollectionArchiveResolution!
      $productFamily: ProductFamily!
      $imageId: Int!
      $context: ImageDownloadContext
    ) {
      imageDownloadLink(
        imageCollectionId: $imageCollectionId
        resolution: $resolution
        productFamily: $productFamily
        imageId: $imageId
        downloadContext: $context
      )
    }
  `;

  private readonly collectionDownloadPinValidationQuery = gql<
    {collectionDownloadPinValidation: boolean},
    ICollectionPinValidationPayload
  >`
    query CollectionDownloadPinValidation($imageCollectionId: String!, $downloadPin: String!) {
      collectionDownloadPinValidation(imageCollectionId: $imageCollectionId, downloadPin: $downloadPin)
    }
  `;

  getArchiveLinks(
    imageCollectionId: string,
    resolution: CollectionArchiveResolution,
    downloadPin: string | undefined,
    imageIds: number[],
    downloadType: CollectionDownloadType
  ): Observable<CollectionArchiveLinks> {
    let context: ICollectionArchiveContext = {imageIds, downloadPin, downloadType};

    if (context.imageIds?.length) {
      context = {
        imageIds: context.imageIds,
        downloadPin: context.downloadPin,
        downloadType: context.downloadType,
      };
    } else {
      context = {
        downloadPin: context.downloadPin,
        downloadType: context.downloadType,
      };
    }

    return this.apollo
      .query({
        query: this.collectionArchiveLinksQuery,
        variables: {
          imageCollectionId,
          productFamily: this.product,
          resolution,
          context,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(result => !!result.data),
        map(({data: {collectionArchiveLinks}}) => plainToInstance(CollectionArchiveLinks, collectionArchiveLinks))
      );
  }

  createArchive(
    imageCollectionId: string,
    resolution: CollectionArchiveResolution,
    downloadPin: string | undefined,
    imageIds: number[] | undefined,
    downloadType: CollectionDownloadType,
    visitorEmail?: string,
    requestSource?: DownloadRequestSource
  ): Observable<CollectionArchiveLinks> {
    return this.apollo
      .mutate({
        mutation: this.requestCollectionArchiveMutation,
        variables: {
          imageCollectionId,
          resolution,
          productFamily: this.product,
          context: {imageIds, agreeToTerms: true, downloadPin, visitorEmail, downloadType, requestSource},
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(CollectionArchiveLinks, data?.requestCollectionArchive))
      );
  }

  createArchivePublic(
    imageCollectionId: string,
    resolution: CollectionArchiveResolution,
    downloadPin: string | undefined,
    downloadType: CollectionDownloadType,
    visitorEmail: string
  ): Observable<CollectionArchiveLinks> {
    return this.apollo
      .mutate({
        mutation: this.requestCollectionArchivePublicMutation,
        variables: {
          imageCollectionId,
          resolution,
          productFamily: this.product,
          context: {
            agreeToTerms: true,
            downloadPin,
            visitorEmail,
            downloadType,
          },
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(CollectionArchiveLinks, data?.requestCollectionArchivePublic))
      );
  }

  getImageDownloadLink(
    imageCollectionId: string,
    imageId: number,
    resolution: CollectionArchiveResolution,
    downloadPin?: string
  ): Observable<string> {
    return this.apollo
      .query({
        query: this.imageDownloadLinkQuery,
        variables: {
          imageCollectionId,
          productFamily: this.product,
          resolution,
          imageId,
          context: {downloadPin},
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => data?.imageDownloadLink)
      );
  }

  validateDownloadPin(imageCollectionId: string, downloadPin: string): Observable<boolean> {
    return this.apollo
      .query({
        query: this.collectionDownloadPinValidationQuery,
        variables: {
          imageCollectionId,
          downloadPin,
        },
        fetchPolicy: 'network-only',
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => data?.collectionDownloadPinValidation)
      );
  }
}
