import {IImageCollectionResponse, ImageCollectionModel} from './image-collection.model';
import {Transform, Type} from 'class-transformer';
import {IVisitorResponse, VisitorModel} from './visitor.model';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import dayjs, {Dayjs} from 'dayjs';

export interface IFavoriteImageResponse {
  image: IImageCollectionResponse;
  state: boolean;
  visitor: IVisitorResponse;
  dateCreated: number;
  dateUpdated: number;
}

export class FavoriteImageModel {
  @Type(() => ImageCollectionModel)
  image!: ImageCollectionModel;
  state!: boolean;

  @Type(() => VisitorModel)
  visitor!: VisitorModel;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateCreated!: Dayjs;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateUpdated!: Dayjs;

  productFamily!: ProductFamily;
}
