import dayjs, {Dayjs} from 'dayjs';
import {Transform, Type} from 'class-transformer';
import {IImageCollection, ImageCollectionModel} from './image-collection';

export interface IHiddenImageResponse {
  state: boolean;
  dateCreated: number;
  dateUpdated: number;
  image: IImageCollection;
}

export class HiddenImageModel {
  state: boolean;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateCreated: Dayjs;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateUpdated: Dayjs;

  @Type(() => ImageCollectionModel)
  image: ImageCollectionModel;
}
