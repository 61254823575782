import {Transform, Type} from 'class-transformer';
import dayjs, {Dayjs} from 'dayjs';
import {GalleryImageId} from '../interfaces/image-id.interface';
import {IImageCollectionResponse, ImageCollectionModel} from './image-collection.model';

export interface IVisitorSubmissionResponse {
  dateCreated: number;
  images: IImageCollectionResponse[];
}

export class VisitorSubmissionModel {
  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateCreated!: Dayjs;

  @Type(() => ImageCollectionModel)
  images!: ImageCollectionModel[];

  get favoritesSnapshot(): GalleryImageId[] {
    return this.images.filter(item => !!item).map(item => item.getId());
  }
}
